<template>
   <header>
      <div class="topbar d-flex align-items-center">
         <nav class="navbar navbar-expand">
            <div class="mobile-toggle-menu" @click="toggleMobileMenu"><i class='bx bx-menu'></i></div>
            <div class="top-menu ms-auto">
               <ul class="navbar-nav align-items-center">
                  <li class="nav-item">
                     <a class="nav-link position-relative" href="javascript:;" role="button" @click="deslogar" title="Voltar para loja">
                        <i class="fal fa-store font-16"></i>
                     </a>
                  </li>
               </ul>
            </div>
            <div class="user-box dropdown">
               <a class="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret pe-0" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <img src="@/assets/admin/images/user.png" class="user-img" alt="Avatar">
                  <div class="user-info ps-2 ms-1">
                     <p class="user-name mt-1 mb-0">{{ dadosUsuario.admin.nome.length >= 15 ? dadosUsuario.admin.nome.substring(0, 15) + '...' : dadosUsuario.admin.nome }}</p>
                  </div>
               </a>
               <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                     <a class="dropdown-item" href="javascript:;" @click="abrirEditarSenha">
                        <i class="far fa-lock me-2"></i><span> Trocar senha</span>
                     </a>
                  </li>
                  <li>
                     <a class="dropdown-item" href="javascript:;" @click="deslogar">
                        <i class="far fa-sign-out-alt me-2"></i><span> Sair</span>
                     </a>
                  </li>
               </ul>
            </div>
         </nav>
      </div>

      <!-- Mudar tema -->
      <div class="switcher-wrapper">
         <div class="switcher-btn" @click="toggleMenuTemas"><i class='bx bx-cog bx-spin'></i></div>
         <div class="switcher-body">
            <div class="d-flex align-items-center">
               <h5 class="mb-0 text-uppercase">Tema</h5>
               <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close" @click="toggleMenuTemas"></button>
            </div>
            <hr/>
            <h6 class="mb-0"><i class="far fa-paint-brush color-theme font-13 me-1"></i> Cor</h6>
            <hr/>
            <div class="header-colors-indigators">
               <div class="row row-cols-auto g-3">
                  <div class="col">
                     <div class="indigator headercolor1" id="headercolor1" @click="toggleTemaCor('headercolor1')"></div>
                  </div>
                  <!-- <div class="col">
                     <div class="indigator headercolor2" id="headercolor2" @click="toggleTemaCor('headercolor2')"></div>
                  </div> -->
                  <div class="col">
                     <div class="indigator headercolor3" id="headercolor3" @click="toggleTemaCor('headercolor3')"></div>
                  </div>
                  <div class="col">
                     <div class="indigator headercolor4" id="headercolor4" @click="toggleTemaCor('headercolor4')"></div>
                  </div>
                  <div class="col">
                     <div class="indigator headercolor5" id="headercolor5" @click="toggleTemaCor('headercolor5')"></div>
                  </div>
                  <div class="col">
                     <div class="indigator headercolor6" id="headercolor6" @click="toggleTemaCor('headercolor6')"></div>
                  </div>
                  <div class="col">
                     <div class="indigator headercolor7" id="headercolor7" @click="toggleTemaCor('headercolor7')"></div>
                  </div>
                  <div class="col">
                     <div class="indigator headercolor8" id="headercolor8" @click="toggleTemaCor('headercolor8')"></div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <!-- modalEditarSenha -->
      <div class="modal fade" id="modalEditarSenha" tabindex="-1" aria-labelledby="modalEditarSenhaLabel" aria-hidden="true">
         <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalEditarSenhaLabel">Editar senha</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <div class="card mb-0">
                     <div class="card-body">
                        <div class="row m-0">
                           <div class="col-12 px-1 mb-3">
                              <label class="mb-1"><i class="far fa-lock color-theme font-12 me-1"></i> Senha atual</label>
                              <input type="text" class="form-control key" v-model="modalSenha.atual" />
                           </div>
                           <div class="col-12 px-1">
                              <label class="mb-1"><i class="far fa-lock-alt color-theme font-12 me-1"></i> Senha nova</label>
                              <input type="text" class="form-control key" v-model="modalSenha.nova" @keyup.enter="salvarSenha" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="salvarSenha">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>
   </header>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'

export default {
	name: 'MenuNavBar',
   data: function() {
		return {
         themeColor: (localStorage.ccmAdminColor == null ? 'color-header headercolor1' : 'color-header '+ localStorage.ccmAdminColor),
         modalSenha: {'atual': '', 'nova': ''}
		}
	},
   computed: {
      ... mapState({
         errorOnLoadImg: state => state.errorOnLoadImg,
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest,
         logado: state => state.logado
      })
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
		deslogar : function () {
			this.$store.dispatch('deslogarAdmin')
		},
      toggleMobileMenu : function () {
         $(".wrapper").addClass("toggled")
      },
      toggleMenuTemas : function () {
         $(".switcher-wrapper").toggleClass("switcher-toggled")
      },
      toggleTemaCor : function (cor) {
         $("html").attr("class", "color-header "+ cor)
         this.themeColor = "color-header "+ cor;
         localStorage.ccmAdminColor = cor;
      },
      abrirEditarSenha : function () {
         this.modalSenha = {'atual': '', 'nova': ''}
         $('#modalEditarSenha').modal('show')
      },
      salvarSenha : function () {
         if (this.modalSenha.nova.trim().length > 0 && this.modalSenha.nova.trim().length < 4) {
            this.$toast.fire({
               icon: 'error',
               title: 'Senha deve ter 4 caracteres!'
            })

            return
         }

			this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'admin/login/trocarSenha',
				headers: {
               'Content-Type': 'application/json'
            },
            data: this.modalSenha

         }).then(() => {
            this.modalSenha = {'atual': '', 'nova': ''}
				$('#modalEditarSenha').modal('hide')

            this.$toast.fire({
               icon: 'success',
               title: 'Senha alterada!'
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogarAdmin')

                  this.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else if (error.response.status == 400) {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Senha incorreta!'
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      }
	}
}

</script>
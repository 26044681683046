<template>
   <div class="card mb-1" :class="variacao.codigo == selecionado ? 'border-theme' : ''">
      <div class="card-body p-12">
         <div class="row m-0 align-items-center">
            <div class="col-xl px-1 product-group-left text-center text-xl-start cursor-pointer" @click="select">
               <h3 class="limitador weight-600 color-theme mb-0">
                  <a href="javascript:;"><i v-if="variacao.codigo == selecionado" class="fas fa-angle-right font-12 color-theme me-2"></i>{{ variacao.nome }}</a>
               </h3>
               <span class="d-block weight-600 mb-1">Cód. {{ String(variacao.codigo).substring(0, 2) +"-"+ String(variacao.codigo).substring(2, String(variacao.codigo).length) }}</span>
               <span class="d-block limitador">{{ variacao.descricao == null ? 'Sem descrição' : variacao.descricao }}</span>
            </div>
            <div class="wpx-125 px-1 text-center mx-auto mx-md-none mt-2 mt-xl-0" v-if="logado.cliente && variacao.preco">
               <span class="color-theme font-15"><small>R$ </small>{{ variacao.preco == null ? '-' : parseFloat(variacao.preco).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
               <span class="badge bg-theme d-none d-xl-inline-block" v-if="variacao.lancamento == 'S'">Lançamento</span>
               <i class="far fa-bell-on color-theme d-inline d-xl-none ms-2" v-if="variacao.lancamento == 'S'"></i>
            </div>
            <div class="wpx-125 px-1 text-center mx-auto mx-md-none mt-2 mt-xl-0" v-if="logado.cliente && variacao.preco">
               <div class="input-group input-quantity">
                  <button name="btn-minus" @click.stop="variacao.quantidade = variacao.quantidade - variacao.qtdMult;" :disabled="variacao.quantidade <= variacao.qtdMult">-</button>
                  <input type="number" v-model="variacao.quantidade" @blur="variacao.quantidade = Math.ceil(variacao.quantidade / variacao.qtdMult) * variacao.qtdMult">
                  <button name="btn-plus" @click.stop="variacao.quantidade = variacao.quantidade + variacao.qtdMult;">+</button>
               </div>
            </div>
            <div class="wpx-125 px-1 text-center mx-auto mx-md-none mt-2 mt-xl-0" v-if="logado.cliente && variacao.preco && parseInt(variacao.quantidade) > 0">
               <button class="btn text-white font-13" @click="addCarrinho" :class="variacao.codigo == selecionado ? 'bg-theme' : 'bg-danger'">
                  <i class="far fa-shopping-cart me-1"></i> Carrinho
               </button>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
   name: 'Variacao',
   props: ['variacao', 'selecionado'],
   computed: {
		... mapState({
			logado: state => state.logado
		})
	},
   methods: {
      select : function () {
         this.$emit('select', this.variacao)
      },
      addCarrinho : function () {
         this.$emit('carrinho', JSON.parse(JSON.stringify(this.variacao)))
         this.variacao.quantidade = this.variacao.qtdMult;
      }
   }
}

</script>

<style scoped>

.product-group-left {
   line-height: 1.5;
}

</style>
<template>
	<div id="app" v-if="isRouteAdmin">
		<div class="body-loading" v-if="isCarregando == true">
			<div class="clock-loader"></div>
		</div>

		<div class="wrapper" v-if="logado.admin">
			<!-- Menu Lateral -->
			<Lateral />

			<!-- Menu NavBar -->
			<NavBar />
			
			<!-- Corpo sistema -->
			<div class="page-wrapper">
				<div class="page-content pb-5">
					<h5 class="mb-3">{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/").replace("Adm", "") : '' }}</h5>
					
					<router-view />
				</div>

				<a href="javaScript:;" class="back-to-top" @click="backToTop">
					<i class='fas fa-arrow-up'></i>
				</a>
			</div>

			<div class="overlay toggle-icon" @click="toggleMobileMenu"></div>
			
			<footer class="page-footer">
				<span class="ps-2 float-start d-none d-md-block">Copyright © 2024. Todos direitos reservados</span>
				<span class="pe-2 float-end">OneWay Systems</span>
			</footer>
		</div>

		<Login v-else />
   </div>

	<div id="app" v-else>
		<div id="loading" v-if="isCarregando == true">
			<div id="loading-center">
				<div id="loading-center-absolute">
					<img id="object" src="@/assets/cliente/img/logo/logo-white.png" alt="logo">
				</div>
			</div>
		</div>

		<a href="javascript:;" class="back-to-top" @click="backToTop">
			<i class='fas fa-arrow-up'></i>
		</a>
		
		<Header v-show="$route.name != 'Produto_Divulgação' && $route.name != 'Formulário'" />

		<div class="offcanvas__area">
			<div class="offcanvas__wrapper">
				<div class="offcanvas__close">
					<button class="offcanvas__close-btn mt-sm-2" id="offcanvas__close-btn" @click="fecharMenu">
						<i class="fal fa-times"></i>
					</button>
				</div>
				<div class="offcanvas__content">
					<div class="offcanvas__logo mb-15">
						<a href="javascript:;">
							<img src="@/assets/cliente/img/logo/logo-black.png" alt="logo">
						</a>
					</div>
					<div class="offcanvas__search mb-25">
						<form action="javascript:;">
							<input type="text" placeholder="Pesquisar..." v-model="pesquisa.valor" @keyup.enter="pesquisar">
							<button @click="pesquisar"><i class="far fa-search"></i></button>
						</form>
					</div>
					<div class="mobile-menu fix mean-container">
						<div class="mean-bar">
							<nav class="mean-nav">
								<ul>
									<li><router-link to="/historia" class="weight-400">História</router-link></li>
                           <li><router-link to="/categorias" class="weight-400">Produtos</router-link></li>
                           <li><router-link to="/noticias" class="weight-400">Notícias</router-link></li>
                           <li><router-link to="/downloads" class="weight-400">Downloads</router-link></li>
                           <li><router-link to="/trabalheConosco" class="weight-400">Trabalhe conosco</router-link></li>
                           <li><router-link to="/encontreRevenda" class="weight-400">Encontre uma revenda</router-link></li>
                           <li><router-link to="/assistenciaTecnica" class="weight-400">Assistência Técnica</router-link></li>
									<li><router-link :to="logado.cliente ? '/perfil' : '/login'" class="weight-400">Área do revendedor</router-link></li>
								</ul>
							</nav>
						</div>
					</div>
					<div class="offcanvas__action"></div>
				</div>
			</div>
		</div>
		<div class="body-overlay" @click="fecharMenu"></div>

		<router-view />

		<Footer v-show="$route.name != 'Produto_Divulgação' && $route.name != 'Formulário'" />

		<!-- Selecionar variacao -->
		<div class="modal fade" id="modalVariacoes" tabindex="-1" role="dialog" aria-hidden="true">
			<div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
				<div class="modal-content">
					<div class="modal-header">
                  <h5 class="modal-title font-16 weight-400 lh-base" id="modalVariacoesLabel">Selecione uma variação</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
					<div class="modal-body product__details-group">
						<variacao v-for="(v, index) in carrinho.variacoes" :key="index" :variacao="v" @carrinho="addCarrinho($event)" />
               </div>
            </div>
         </div> 
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import Vue from 'vue'
import router from '@/router'
import { mapState } from 'vuex'
import Login from '@/views/admin/Login.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import NavBar from '@/components/admin/MenuNavBar.vue'
import Lateral from '@/components/admin/MenuLateral.vue'
import variacao from '@/components/produtos/Variacao.vue'

export default {
	name: 'App',
	data: function () {
		return {
			pesquisa: {'categoria': '', 'subcategoria': '', 'tipoPesquisa': 'Geral', 'valor': ''}
		}
	},
	computed: {
		... mapState({
			listaCategorias: state => state.listaCategorias,
         isCarregando: state => state.isCarregando,
         isRouteAdmin: state => state.isRouteAdmin,
			clienteData: state => state.clienteData,
         carrinho: state => state.carrinho,
         logado: state => state.logado
		})
	},
	components: {
		Footer, Header, Login, Lateral, NavBar, variacao
	},
	methods: {
      pesquisar : function () {
         this.$store.dispatch('setPesquisa', this.pesquisa)
      },
		addCarrinho : function (produto) {
         this.$store.dispatch('addCarrinho', JSON.parse(JSON.stringify(produto)))
         $("#modalVariacoes").modal("hide")
      },
		fecharMenu : function () {
			$(".offcanvas__area").removeClass("opened");
			$(".body-overlay").removeClass("opened");
		},
		toggleMobileMenu : function () {
         $(".wrapper").hasClass("toggled") ? ($(".wrapper").removeClass("toggled"), $(".sidebar-wrapper").unbind("hover")) : ($(".wrapper").addClass("toggled"), $(".sidebar-wrapper").hover(function() {
            $(".wrapper").addClass("sidebar-hovered")
         }, function() {
            $(".wrapper").removeClass("sidebar-hovered")
         }))
      },
		backToTop : function () {
			if (this.isRouteAdmin) {
				$('.page-content').animate({scrollTop:0}, '300');
			} else {
				window.scrollTo(0, 0)
			}
		}
	},
	mounted() {
		router.push('/').catch(function(){})

		// this.$store.dispatch('getData').then(() => {
			this.$store.dispatch('login', {'redirect': true})
		// })

		Vue.prototype.$axios.interceptors.request.use((config) => {
			config.headers.Authorization = this.logado.token;
			return config;
		});

		Vue.nextTick(function() {
         $(document).scroll(function() {
            if ($(document).scrollTop() > 300) {
               $('.back-to-top').fadeIn();
            } else {
               $('.back-to-top').fadeOut();
            }
         });
		}.bind(this));
	}
}

</script>
<template>
   <div class="sidebar-wrapper" data-simplebar="true">
      <div class="sidebar-header">
         <div>
            <img src="@/assets/admin/images/logo-icon.png" class="logo-icon" alt="logo icon">
         </div>
         <div>
            <img src="@/assets/admin/images/logo-text.png" class="logo-img-text" alt="logo text">
         </div>
         <div class="toggle-icon ms-auto" @click="toggleMobileMenu"><i class="fal fa-arrow-to-left font-18"></i></div>
      </div>

      <ul class="metismenu pb-5" id="menu">
         <li class="my-2" :class="$route.path == '/admPaginaInicial' ? 'mm-active' : ''">
            <router-link to="/admPaginaInicial" class="px-2">
               <div class="parent-icon"><i class="far fa-home"></i></div>
               <div class="menu-title">Página inicial</div>
            </router-link>
         </li>
         <li class="my-0" :class="$route.path == '/admBlog' ? 'mm-active' : ''">
            <router-link to="/admBlog" class="px-2">
               <div class="parent-icon"><i class="far fa-share-alt"></i></div>
               <div class="menu-title">Blog</div>
            </router-link>
         </li>
      </ul>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'

export default {
	name: 'MenuLateral',
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario
      })
   },
   methods: {
      toggleMobileMenu : function () {
         $(".wrapper").hasClass("toggled") ? ($(".wrapper").removeClass("toggled"), $(".sidebar-wrapper").unbind("hover")) : ($(".wrapper").addClass("toggled"), $(".sidebar-wrapper").hover(function() {
            $(".wrapper").addClass("sidebar-hovered")
         }, function() {
            $(".wrapper").removeClass("sidebar-hovered")
         }))
      }
   }
}

</script>
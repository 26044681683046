<template>
   <li>
      <div class="cart__item d-flex justify-content-between align-items-center">
         <div class="cart__inner d-flex align-items-center">
            <div class="cart__thumb">
               <a href="javascript:;" class="ratio ratio-1x1">
                  <img :src="produto.foto == null ? '' : produto.foto" alt="produto" @error="imageError">
               </a>
            </div>
            <div class="cart__details">
               <h6 class="limitador-2">{{ produto.nome }}</h6>
               <div class="cart__desc limitador-1">Cód. {{ String(produto.codigo).substring(0, 2) +"-"+ String(produto.codigo).substring(2, String(produto.codigo).length) }}</div>
               <div class="cart__price limitador-1">
                  <span>R$ {{ produto.preco == null ? '-' : parseFloat(produto.preco).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</span>
                  <span class="font-12 text-secondary mx-2">x</span>
                  <span>{{ produto.quantidade == null ? 1 : produto.quantidade }} Unid.</span>
                  <span class="font-12 text-secondary mt-1 d-block">Total com impostos: R$ {{ produto.totalComImposto == null ? '-' : parseFloat(produto.totalComImposto).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</span>
               </div>
            </div>
         </div>
         <div class="cart__del">
            <a href="javascript:;" @click="removeCarrinho"><i class="far fa-trash text-danger"></i></a>
         </div>
      </div>
   </li>
</template>

<script>

export default {
   name: 'Carrinho',
   props: ['produto', 'index', 'view', 'produtoSelecionado'],
   data : function () {
      return {
         timeout: null
      }
   },
   methods: {
      removeCarrinho: function () {
         this.$store.dispatch('removeCarrinho', this.index)
      },
      remover: function () {
         this.$emit('remover', this.index)
      },
      selectProdutoSolicitacao: function () {
         this.$emit('select', this.produto)
      },
      alterarQuantidade : function () {
         clearTimeout(this.timeout);
         
         this.timeout = setTimeout(() => {
            this.produto.quantidade = Math.ceil(this.produto.quantidade / this.produto.qtdMult) * this.produto.qtdMult
            this.$store.dispatch('alterarQuantidadeCarrinho', this.produto)
         }, 500);
      },
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      }
   }
}

</script>

<style scoped>

img {
	aspect-ratio: 1 / 1;
	object-fit: cover;
}

</style>
<template>
   <div class="wrapper logout bg-login">
		<div class="section-authentication-signin d-flex align-items-center justify-content-center my-0">
			<div class="container-fluid">
				<div class="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
					<div class="col mx-auto">
						<div class="card">
							<div class="card-body">
								<div class="border p-4 rounded">
									<div class="text-center">
                              <div class="mb-4 text-center">
                                 <img src="@/assets/admin/images/logo-login.png" class="logo-login" width="250" alt="" />
                              </div>
                              <h6 class="mb-3 font-18">Faça login para continuar</h6>
                           </div>
									<div class="form-body">
										<form class="row g-3">
                                 <div class="col-12">
                                    <label class="color-theme mb-1"><i class="far fa-user me-1 font-12"></i> Usuário</label>
                                    <input type="text" class="form-control font-14" placeholder="Digite seu usuário" v-model="dados.usuario" @keyup.enter="verificarLogin">
                                 </div>
                                 <div class="col-12">
                                    <label class="color-theme mb-1"><i class="far fa-lock me-1 font-12"></i> Senha</label>
                                    <input type="password" class="form-control font-14" placeholder="Digite sua senha" v-model="dados.senha" @keyup.enter="verificarLogin">
                                 </div>
                                 <div class="col-sm-6">
                                    <div class="form-check form-switch w-max-content mx-auto mx-sm-0">
                                       <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" v-model="dados.manterConectado">
                                       <label class="form-check-label" for="flexSwitchCheckChecked">Mantenha-me conectado</label>
                                    </div>
                                 </div>
                                 <div class="col-sm-6 text-center text-sm-end">
                                    <span class="cursor-pointer" @click="setRouteCliente"><i class="far fa-external-link font-11 me-1"></i> Voltar para loja</span>
                                 </div>
                                 <div class="col-12">
                                    <div class="d-grid">
                                       <button type="button" class="btn btn-primary font-14" @click="verificarLogin">
                                          <i class="far fa-sign-in-alt me-1"></i> Entrar
                                       </button>
                                    </div>
                                 </div>
                              </form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'AdmLogin',
   data: function() {
		return {
			dados: {'usuario': '', 'senha': '', 'manterConectado': false}
		}
	},
   methods: {
      setRouteCliente : function () {
         this.$store.dispatch('isRouteAdmin', false)
      },
		verificarLogin : function () {
         if (this.dados.usuario.length > 0 && this.dados.senha.length > 0) {
            this.$store.dispatch('loginAdmin', {'usuario': this.dados.usuario, 'senha': this.dados.senha, 'manterConectado': this.dados.manterConectado});
         }
		}
	},
   mounted() {
      this.$store.dispatch('loginAdmin')
   }
}

</script>

<style scoped>

label:not(.form-check-label) {
   font-weight: 600 !important;
}

</style>
<template>
   <li v-if="page == 'Header'" class="w-max-content">
      <a class="p-0 limitador font-13" href="javascript:;" @click="pesquisar"><span>{{ categoria.nome }}</span></a>
      <ul class="submenu row mx-0" v-show="categoria.subcategorias.length > 0" :style="index > Math.ceil(length / 2) ? 'left: unset; right: -50px;' : ''">
         <li class="col-8 px-3 py-12 border-right">
            <span class="row m-0">
               <subcategoria v-for="(subcategoria, index) in categoria.subcategorias" :key="index" :subcategoria="subcategoria" :page="'Header'" :categoria="categoria.nome" />
            </span>
            <a class="p-0 text-end" href="javascript:;" @click="pesquisar">
               <span class="font-14 weight-600 color-theme"><i class="fal fa-chevron-right font-10 me-1"></i> Todos os produtos</span>
            </a>
         </li>
         <li class="col-4 p-0 align-self-end">
            <a class="w-img position-relative p-0" href="javascript:;" @click="pesquisar">
               <img :src="categoria.foto == null ? '' : categoria.foto" alt="categoria" @error="imageError">
               <span>{{ categoria.nome }}</span>
            </a>
         </li>
      </ul>
   </li>

   <li v-else-if="page == 'Footer'" class="not-dropdown"><a href="javascript:;" @click="pesquisar">{{ categoria.nome }}</a></li>
   
   <div v-else-if="page == 'Categorias'" class="card cursor-pointer" @click="pesquisar">
      <img :src="categoria.foto == null ? '' : categoria.foto" class="card-img" alt="categoria" @error="imageError">
      <div class="card-img-overlay p-12 d-flex align-items-end">
         <h5 class="card-text font-15 mb-0">{{ categoria.nome }}</h5>
      </div>
   </div>
</template>

<script>

import subcategoria from '@/components/home/Subcategoria.vue'

export default {
   name: 'Categoria',
   props: ['categoria', 'page', 'length', 'index'],
   components: {
      subcategoria
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      pesquisar : function () {
         this.$store.dispatch('setPesquisa', {'categoria': this.categoria.nome, 'subcategoria': '', 'tipoPesquisa': 'Categoria', 'valor': this.categoria.nome})
      }
   }
}

</script>

<style scoped>

.border-right {
   border-right: 1px solid #ebebeb;
}

.border-right::after {
   display: none;
}

.card::after {
   content: '';
   display: block;
   height: 100%;
   position: absolute;
   top: 0;
   width: 100%;
}

.card-text {
   text-shadow: 1px 1px 3px #000;
   color: #fff;
   z-index: 1;
}

.submenu li a.position-relative span {
   position: absolute;
   bottom: 4px;
   left: 8px;
   right: 8px;
	color: #fff;
	text-shadow: 1px 1px 1px #000;
   font-size: 16px !important;
}

.submenu li:last-child {
   line-height: 1.5 !important;
}

li:not(.not-dropdown) a {
   font-weight: 400 !important;
   /* text-transform: capitalize !important; */
   color: rgb(81, 90, 108) !important;
}

li {
   line-height: 2 !important;
}

.submenu li {
   line-height: 2.2 !important;
}

.submenu img {
	aspect-ratio: 1 / 1;
}

.submenu i {
   position: unset !important;
   top: unset !important;
   right: unset !important;
   transform: unset !important;
}

* {
   cursor: auto;
}

a span,
a img {
   cursor: pointer !important;
}

</style>
<template>
	<header>
      <div class="header__area">
         <div class="header__top d-none d-sm-block bg-theme text-white">
            <div class="container px-0 px-md-3">
               <div class="row align-items-center">
                  <div class="col-xl-6 col-lg-5 d-none d-lg-block">
                     <div class="header__welcome">
                        <router-link to="/perfil" class="limitador" v-if="logado.cliente">
                           Bem-vindo <i>{{ dadosUsuario.cliente.nome }} {{ dadosUsuario.cliente.nomePerfil == null ? '' : ('('+ dadosUsuario.cliente.nomePerfil +')') }}</i> !
                        </router-link>
                        <router-link to="/login" class="limitador" v-else>Bem-vindo visitante!</router-link>
                     </div>
                  </div>
                  <div class="col-xl-6 col-lg-7 pe-lg-0">
                     <div class="header__action d-flex justify-content-center justify-content-lg-end">
                        <ul>
                           <li><a :href="'tel:'+ contato.telefone"><i class="far fa-phone-alt font-12 me-1"></i> {{ contato.telefone == null ? '-' : contato.telefone }}</a></li>
                           <li><a :href="'mailto:'+ contato.email"><i class="far fa-envelope font-12 me-1"></i> {{ contato.email == null ? '-' : contato.email }}</a></li>
                           <li><a href="javascript:;" @click="setRouteAdmin"><i class="far fa-lock font-12 me-1"></i> Área administrativa</a></li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div class="header__info py-md-3">
            <div class="container px-0">
               <!-- Atalhos -->
               <div class="row m-0 align-items-center d-none d-md-flex">
                  <div class="col-xxl-2"></div>
                  <div class="col-xxl-8 px-0">
                     <div class="header__action">
                        <ul class="d-flex flex-wrap justify-content-center">
                           <li class="lh-lg"><router-link to="/historia" class="limitador">História</router-link></li>
                           <li class="lh-lg"><router-link to="/categorias" class="limitador">Produtos</router-link></li>
                           <li class="lh-lg"><router-link to="/noticias" class="limitador">Notícias</router-link></li>
                           <li class="lh-lg"><router-link to="/downloads" class="limitador">Downloads</router-link></li>
                           <li class="lh-lg"><router-link to="/trabalheConosco" class="limitador">Trabalhe conosco</router-link></li>
                           <li class="lh-lg"><router-link to="/encontreRevenda" class="limitador">Encontre uma revenda</router-link></li>
                           <li class="lh-lg"><router-link to="/assistenciaTecnica" class="limitador">Assistência Técnica</router-link></li>
                           <li class="lh-lg text-danger"><router-link :to="logado.cliente ? '/perfil' : '/login'" class="limitador">Área do revendedor</router-link></li>
                        </ul>
                     </div>
                  </div>
                  <div class="col-xxl-2"></div>
               </div>
               
               <div class="row align-items-center">
                  <!-- Logo -->
                  <div class="col-md-2 mb-2 mb-md-0">
                     <div class="header__info-left d-flex justify-content-center justify-content-md-end align-items-center">
                        <div class="logo">
                           <router-link to="/">
                              <img src="@/assets/cliente/img/logo/logo-black.png" alt="logo">
                           </router-link>
                        </div>
                     </div>
                  </div>

                  <!-- Pesquisa -->
                  <div class="col-md-7 col-xl-8 d-none d-md-block">
                     <div class="header__info-right">
                        <div class="header__search w-100">
                           <form action="javascript:;">
                              <div class="header__search-box">
                                 <input type="text" placeholder="Tipo, nome ou código" v-model="pesquisa.valor" @keyup.enter="pesquisar">
                                 <button @click="pesquisar"><i class="far fa-search d-inline d-xl-none"></i><span class="d-none d-xl-inline">Pesquisar</span></button>
                              </div>
                              <div class="header__search-cat">
                                 <v-select title="Desktop" :searchable="false" class="border-0" :options="['Geral', 'Peças']" v-model="pesquisa.tipoPesquisa" placeholder="Classe" />
                                 <select title="Mobile" class="form-control border-0" v-model="pesquisa.tipoPesquisa">
                                    <option v-for="(option, index) in ['Geral', 'Peças']" :key="index" :value="option">{{ option }}</option>
                                 </select>
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>

                  <!-- Carrinho -->
                  <div class="col-md-3 col-xl-2 d-flex">
                     <div class="col align-self-center d-block d-md-none">
                        <button type="button" class="side-menu-btn offcanvas-toggle-btn ml-25" @click="abrirMenu">
                           <i class="far fa-bars font-20 mb-2"></i>
                        </button>
                     </div>
                     <div class="w-max-content" v-if="codigosComparacao.length > 0">
                        <router-link to="/produtoComparacao/state" class="compare__toggle mt-1">
                           <span class="compare__total-item">{{ codigosComparacao.length }}</span>
                        </router-link>
                     </div>
                     <div class="w-max-content">
                        <div class="cart__mini-wrapper p-relative pt-1" v-show="logado.cliente">
                           <a href="javascript:;" class="cart__toggle" @click="abrirCarrinho">
                              <span class="cart__total-item" v-if="carrinho.itens.length > 0">{{ carrinho.itens.length }}</span>
                           </a>
                           <div class="cart__mini pb-4 pt-3">
                              <div class="cart__close">
                                 
                                 <button type="button" class="cart__close-btn" @click="fecharCarrinho"><i class="fal fa-times"></i></button>
                              </div>
                              <ul>
                                 <li>
                                    <div class="cart__title pb-12">
                                       <h4 class="font-16">Carrinho</h4>
                                       <span class="ms-2" v-if="carrinho.itens.length > 0"> {{ carrinho.itens.length }} produto(s)</span>
                                    </div>
                                 </li>
                                 <carrinho v-for="(produto, index) in carrinho.itens" :key="index" :produto="produto" :index="index" :view="'Header-Popover'" />
                                 <li>
                                    <div class="cart__item d-flex justify-content-between align-items-center" v-if="carrinho.itens.length == 0">
                                       <div class="cart__inner d-flex mx-auto my-5">
                                          <div class="cart__details">
                                             <h6><a href="javascript:;">Carrinho vazio</a></h6>
                                          </div>
                                       </div>
                                    </div>
                                 </li>
                                 <li v-if="carrinho.itens.length > 0">
                                    <div class="cart__sub d-flex justify-content-between align-items-center">
                                       <h6>Total</h6>
                                       <span class="cart__sub-total">R${{ parseFloat(carrinho.valorTotal).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</span>
                                    </div>
                                 </li>
                                 <li v-if="carrinho.itens.length > 0">
                                    <div class="row">
                                       <div class="col-6 pe-1">
                                          <router-link to="/perfil" class="t-y-btn w-100">Ver carrinho</router-link>
                                       </div>
                                       <div class="col-6 ps-1">
                                          <router-link to="/perfil" class="t-y-btn t-y-btn-2 w-100">Fechar pedido</router-link>
                                       </div>
                                    </div>
                                 </li>
                              </ul>
                           </div>
                        </div>
                        <router-link to="/login" class="login__icon mt-md-1 mb-2 mb-md-0" v-show="!logado.cliente"></router-link>
                     </div>
                  </div>
               </div>

               <!-- Categorias -->
               <div class="row m-0 mt-1 align-items-center d-none d-md-flex" v-if="clienteData.categorias != null && clienteData.categorias.length > 0">
                  <div class="col-xxl-2"></div>
                  <div class="col-xxl-8 px-0">
                     <div class="header__action main-menu">
                        <ul class="d-flex flex-wrap justify-content-center color-theme">
                           <categoria v-for="(categoria, index) in clienteData.categorias" :key="index" :index="index" :categoria="categoria" :page="'Header'" :length="clienteData.categorias.length" />
                           <li class="wpx-150 pe-0" style="min-width: 150px;">
                              <router-link to="/categorias" role="button" class="btn bg-theme btn-category limitador">
                                 <i class="far fa-bars font-12 me-1"></i> Todas categorias
                              </router-link>
                           </li>
                        </ul>
                     </div>
                  </div>
                  <div class="col-xxl-2"></div>
               </div>
            </div>
         </div>
      </div>
   </header>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import categoria from '@/components/home/Categoria.vue'
import carrinho from '@/components/home/Carrinho.vue'

export default {
	name: 'Header',
   data : function () {
      return {
         pesquisa: {'categoria': '', 'subcategoria': '', 'tipoPesquisa': 'Geral', 'valor': ''}
      }
   },
   computed: {
		... mapState({
         codigosComparacao : state => state.codigosComparacao,
			dadosUsuario: state => state.dadosUsuario,
			clienteData: state => state.clienteData,
			contato: state => state.source.contato,
			carrinho: state => state.carrinho,
			logado: state => state.logado
		})
	},
   components: {
      categoria, carrinho
   },
   methods: {
      setRouteAdmin : function () {
         this.$store.dispatch('isRouteAdmin', true)
      },
      pesquisar : function () {
         this.$store.dispatch('setPesquisa', JSON.parse(JSON.stringify(this.pesquisa)))
         
         setTimeout(() => {
            this.pesquisa = {'categoria': '', 'subcategoria': '', 'tipoPesquisa': 'Geral', 'valor': ''}
         }, 250);
      },
      abrirMenu : function () {
         $(".offcanvas__area").addClass("opened");
         $(".body-overlay").addClass("opened");
      },
      abrirCarrinho : function () {
         $(".cart__mini").addClass("cart__opened");

         if (this.carrinho.toggle == false) {
            this.$store.dispatch('toggleCarrinho', true)

         } else if (this.carrinho.toggle == true) {
            this.$store.dispatch('toggleCarrinho', false)
         }
      },
      fecharCarrinho : function () {
         this.$store.dispatch('toggleCarrinho', false)
      }
   }
}

</script>

<style scoped>

.btn-category {
   color: #fff !important;
   font-size: 13px !important;
   box-shadow: none !important;
   padding: 3px 12px !important;
   text-transform: capitalize !important;
   font-weight: 400 !important;
}

.header__info-right {
   padding-top: 6px;
   padding-bottom: 6px;
}

</style>